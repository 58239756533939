import React from "react";

import { faArrowRight } from "@fortawesome/sharp-regular-svg-icons/faArrowRight";
import { faBuildingColumns } from "@fortawesome/sharp-regular-svg-icons/faBuildingColumns";
import { faCalendar } from "@fortawesome/sharp-regular-svg-icons/faCalendar";
import { faCheck } from "@fortawesome/sharp-regular-svg-icons/faCheck";
import { faChevronRight } from "@fortawesome/sharp-regular-svg-icons/faChevronRight";
import { faCoins } from "@fortawesome/sharp-regular-svg-icons/faCoins";
import { faCreditCard } from "@fortawesome/sharp-regular-svg-icons/faCreditCard";
import { faFile } from "@fortawesome/sharp-regular-svg-icons/faFile";
import { faMoneyBill } from "@fortawesome/sharp-regular-svg-icons/faMoneyBill";
import clsx from "clsx";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";
import { colorTokens_latest } from "~styles/vars/colorTokens_latest";

import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";
import { IconFontAwesome } from "~components/IconFontAwesome";
import { StoryblokEditable } from "~components/StoryblokEditable";
import { ShowChildren } from "~components/VisibilityCheck/ShowChildren";

import { AnimLayerUiCardTitle } from "../AnimLayerUiCardTitle";
import { AnimLayerUiRadioButton } from "../AnimLayerUiRadioButton";
import * as styles from "./styles.css";

import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

const ICON_MAP = {
  faBuildingColumns,
  faCreditCard,
  faMoneyBill,
  faCoins,
  faCheck,
  faCalendar,
  faFile,
  faArrowRight,
  "": undefined,
} as const;

/**
 * ------------------------------------------------------------------------------
 * Abstraction of single payment method
 * ------------------------------------------------------------------------------
 */

export interface AnimLayerIconListItemProps extends StoryblokBlok, BoxProps {
  ctaText?: string;
  description?: string;
  icon?: keyof typeof ICON_MAP;
  leftIcon?: keyof typeof ICON_MAP;
  isArrowVisible?: boolean;
  isRadioButtonChecked?: boolean;
  isRadioButtonVisible?: boolean;
  title: string;
  appearance?: styles.VariantIconListItemAppearanceEnum;
}

export function AnimLayerIconListItem({
  ctaText,
  description,
  icon: rightIcon = "", // default
  leftIcon = "",
  isArrowVisible,
  isRadioButtonChecked,
  isRadioButtonVisible,
  title,
  appearance,
  className,
  ...rest
}: AnimLayerIconListItemProps) {
  const dynamicRightIcon = ICON_MAP[rightIcon] || undefined;
  const dynamicLeftIcon = ICON_MAP[leftIcon] || undefined;

  return (
    <StoryblokEditable {...rest}>
      <Box
        data-testid="AnimLayerIconListItem"
        data-motion={ANIMATED.secondary}
        className={clsx(
          styles.getIconListItemStyles({ appearance }),
          className
        )}
        {...rest}
      >
        <Box
          alignItems="center"
          display="flex"
          gap="phoneSpacing1"
          paddingX="phoneSpacing1"
          paddingY="phoneSpacing1.5"
          textAppearance="phone_text_sm"
        >
          <ShowChildren when={dynamicLeftIcon}>
            <IconFontAwesome
              icon={dynamicLeftIcon!}
              width="icon_md"
              height="icon_md"
            />
          </ShowChildren>
          {title && <Box>{title}</Box>}

          <ShowChildren
            when={isArrowVisible && !ctaText && !isRadioButtonVisible}
          >
            <IconFontAwesome
              marginLeft="auto"
              fontWeight="semibold"
              icon={faChevronRight}
              color="text_lowContrast"
            />
          </ShowChildren>

          <ShowChildren when={!ctaText && isRadioButtonVisible}>
            <AnimLayerUiRadioButton
              marginLeft="auto"
              marginRight="phoneSpacing0"
              isChecked={isRadioButtonChecked}
            />
          </ShowChildren>

          <ShowChildren when={ctaText}>
            <Box
              marginLeft="auto"
              fontWeight="semibold"
              __color={colorTokens_latest.Secondary.Water_azure[130].value}
            >
              {ctaText}
            </Box>
          </ShowChildren>

          <ShowChildren when={dynamicRightIcon}>
            <IconFontAwesome
              icon={dynamicRightIcon!}
              marginLeft="auto"
              width="phoneSpacing2"
              height="phoneSpacing2"
            />
          </ShowChildren>
        </Box>

        <ShowChildren when={description}>
          <Box
            paddingX="phoneSpacing1"
            textAppearance="phone_text_sm"
            color="text_lowContrast"
          >
            {description}
          </Box>
        </ShowChildren>
      </Box>
    </StoryblokEditable>
  );
}

/**
 * ------------------------------------------------------------------------------
 * Component that renders mock ui elements showing a list of available payment methods
 * ------------------------------------------------------------------------------
 */

export interface AnimLayerIconListProps extends StoryblokBlok, BoxProps {
  items: Array<StoryblokBlok & AnimLayerIconListItemProps>;
  areRadioButtonsVisible?: boolean;
  title?: string;
  isArrowVisible?: boolean;
  appearance?: styles.VariantIconListItemAppearanceEnum;
}

export function AnimLayerIconList({
  items,
  title,
  className: userClassName,
  appearance,
  isArrowVisible = true,
  ...rest
}: AnimLayerIconListProps) {
  if (!Array.isArray(items)) return null;

  return (
    <StoryblokEditable {...rest}>
      <Box {...rest} className={userClassName}>
        {title && (
          <AnimLayerUiCardTitle marginBottom="phoneSpacing1" title={title} />
        )}

        <Box display="flex" flexDirection="column" gap="phoneSpacing1">
          {items.map((item) => {
            return (
              <AnimLayerIconListItem
                className={variantBackgroundColor.background_white}
                borderRadius="md"
                isArrowVisible={isArrowVisible}
                appearance={appearance}
                key={item._uid}
                {...item}
              />
            );
          })}
        </Box>
      </Box>
    </StoryblokEditable>
  );
}
